import React from "react"
import { TripsLayer } from "deck.gl"

const TRAIL_COLOR_LIGHT_RED = [227, 11, 92]
const TRAIL_COLOR_DARK_BLUE = [60, 220, 255]

export default function tripsLayer({
  name,
  data,
  count,
  initialTime,
  trailLength,
  colorMode,
}) {
  return new TripsLayer({
    id: name || "trips-layer",
    data,
    // data: processed,
    getPath: d => d.node.waypoints.map(p => p.coordinates),
    getTimestamps: d => d.node.waypoints.map(p => p.timestamp - initialTime),
    getColor:
      colorMode === "light" ? TRAIL_COLOR_LIGHT_RED : TRAIL_COLOR_DARK_BLUE,
    // opacity: 0.3,
    widthMinPixels: 3,
    rounded: true,
    trailLength: trailLength || 200,
    currentTime: count,
    shadowEnabled: false,
  })
}
