/** @jsx jsx */
import { jsx, useColorMode } from "theme-ui"
import React, { useState, useEffect } from "react"
import { ToggleLeft, ToggleRight } from "react-feather"

import SideboxMapView from "../views/sidebox-map-view"
import DeckGLMap from "../maps/deckgl/deckgl-map"
import { FlyToInterpolator } from "deck.gl"
import useAnimationFrame from "../../hooks/useAnimationFrame"
import tripsLayer from "../maps/deckgl/trips-layer"
import LayerSelector from "../widgets/layer-selector"
import {
  DARK_MINIMALIST_3D_BUILDINGS,
  LIGHT_MINIMALIST_3D_BUILDINGS,
} from "../../utils/constants"

const SmartcityStory = ({
  isMobile,
  city,
  data,
  mapOptions,
  handleChangeCity,
  getPosition,
  links,
}) => {
  const [colorMode] = useColorMode()

  const [loadedMap, setLoadedMap] = useState(false)
  const [count, setCount] = useState(0)
  const [viewDim, setViewDim] = useState("3d")
  const [viewState, setViewState] = useState(null)
  const [layerDisplayed, setLayerDisplayed] = useState("trips")

  useEffect(() => {
    setViewState(mapOptions.initialViewState)
  }, [data])

  useAnimationFrame(deltaTime => {
    // Pass on a function to the setter of the state
    // to make sure we always have the latest state
    // loopLength, unit corresponds to the timestamp in source data
    const timestamp = Date.now() / 1000
    const loopTime = mapOptions.loopLength / mapOptions.animationSpeed
    setCount(((timestamp % loopTime) / loopTime) * mapOptions.loopLength)
  })

  const renderLayers = () => {
    return layerDisplayed === "trips"
      ? // return viewDim === "3d"
        [
          tripsLayer({
            name: mapOptions.id,
            data,
            count,
            initialTime: mapOptions.initialTimestamp,
            trailLength: mapOptions.trailLength,
            colorMode,
          }),
        ]
      : [
          // heatmapLayer({
          //   data: dataPoints,
          //   // colorMode,
          //   layerName: mapType,
          //   getPosition,
          //   getWeight,
          //   colorRange,
          //   intensity,
          // }),
        ]
  }

  const onLoadMap = () => {
    setTimeout(() => {
      setLoadedMap(true)
    }, 3000)
    if (isMobile && mapOptions.initialViewStateFinal) {
      setViewState({
        ...mapOptions.initialViewState,
        ...mapOptions.initialViewStateFinal,
        transitionInterpolator: new FlyToInterpolator(),
      })
    }
  }

  const toggleViewDim = () => {
    setViewState(
      viewDim === "3d"
        ? isMobile
          ? {
              ...mapOptions.secondViewState,
              ...mapOptions.secondViewStateMobile,
            }
          : mapOptions.secondViewState
        : isMobile
        ? {
            ...mapOptions.initialViewState,
            ...mapOptions.initialViewStateFinal,
          }
        : mapOptions.initialViewState
    )
    setViewDim(viewDim === "3d" ? "2d" : "3d")
  }

  const onViewStateChange = state => {
    setViewState(state.viewState)
  }

  // DISABLED
  // const toggleLayerDisplayed = () => {
  //   setLayerDisplayed(layerDisplayed === "trips" ? "heatmap" : "trips")
  // }

  return (
    <SideboxMapView
      loadedMap={loadedMap}
      map={
        <DeckGLMap
          mapType={mapOptions.id}
          layers={renderLayers()}
          onLoadMap={onLoadMap}
          extendedInitialView={mapOptions.initialViewState}
          darkBase={DARK_MINIMALIST_3D_BUILDINGS}
          lightBase={LIGHT_MINIMALIST_3D_BUILDINGS}
          viewState={viewState}
          onViewStateChange={onViewStateChange}
        />
      }
      text={
        <>
          <div sx={{ variant: "styles.pageText", fontWeight: "heading" }}>
            How do our cities move?
          </div>
          <p sx={{ variant: "styles.pageTextSmall" }}>
            GeoDB ecosystem allows to improve the movement flow around the
            cities, the traffic, the emergency protocols, the holidays peaks and
            a large etcetera to create efficient cities in terms of speed,
            agility and sustainability.
          </p>

          <div
            sx={{
              display: "grid",
              gridGap: 2,
              gridTemplateColumns: `auto auto`,
              // gridTemplateColumns: `repeat(auto-fit, minmax(50px, 1fr))`,
            }}
          >
            <div>
              <LayerSelector
                defaultValue={city}
                pages={links}
                changeHandler={handleChangeCity}
                small={true}
              />
            </div>

            <div sx={{ display: "flex", flexDirection: "row-reverse", pr: 2 }}>
              {/* Toggle between Trips and Heatmap layers */}
              {/* <div title="Toggle Layer" sx={{ pl: 3 }}>
                <Layers onClick={() => toggleLayerDisplayed()} />
              </div> */}

              {viewDim === "3d" ? (
                <div title="Toggle 2D" sx={{ pl: 3 }}>
                  <ToggleLeft
                    sx={{ cursor: "pointer", verticalAlign: "bottom" }}
                    onClick={() => toggleViewDim()}
                  />
                </div>
              ) : (
                <div title="Toggle 3D" sx={{ pl: 3 }}>
                  <ToggleRight
                    sx={{ cursor: "pointer", verticalAlign: "bottom" }}
                    onClick={() => toggleViewDim()}
                  />
                </div>
              )}
            </div>
          </div>

          <p>
            <small>{mapOptions.description}</small>
          </p>
        </>
      }
    />
  )
}

export default SmartcityStory
