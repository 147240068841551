/** @jsx jsx */
import { jsx } from "theme-ui"
// import React from "react"
import { Select } from "@theme-ui/components"

const LayerSelector = ({ defaultValue, pages, changeHandler, small }) => {
  return (
    <Select
      defaultValue={defaultValue}
      sx={{
        variant: small ? "styles.selectLayerSmall" : "styles.selectLayer",
      }}
      onChange={e => changeHandler(e.target.value)}
    >
      {pages.map(page => (
        <option
          key={`option-${page.id}`}
          value={page.id}
          sx={{
            variant: small
              ? "styles.layerSelectorOptionSmall"
              : "styles.layerSelectorOption",
          }}
        >
          {page.title}
        </option>
      ))}
    </Select>
  )
}

export default LayerSelector
