import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"

import useWindowSize from "../../hooks/useWindowSize"
import usePrevious from "../../hooks/usePrevious"
import Layout from "../../components/layout/layout"
import SEO from "../../components/layout/seo"
import SmartcityStory from "../../components/sections/smartcity-story"

const SCREEN_MOBILE_WIDTH = 639

const SMARTCITIES = [
  {
    id: "madrid",
    title: "Madrid",
    description: "Total City View.",
    initialViewState: {
      latitude: 40.418889,
      longitude: -3.75,
      zoom: 12,
      pitch: 60,
      bearing: 0,
      transitionDuration: 2000,
    },
    initialViewStateFinal: {
      longitude: -3.71,
      zoom: 11,
    },
    secondViewState: {
      latitude: 40.418889,
      longitude: -3.75,
      zoom: 10,
      pitch: 0,
      bearing: 0,
      transitionDuration: 2000,
    },
    secondViewStateMobile: {
      longitude: -3.72,
    },
    initialTimestamp: 1581003300,
    loopLength: 1500,
    animationSpeed: 100,
    trailLength: null,
  },
  {
    id: "london",
    title: "London",
    description: 'London´s financial district "The city" 3D Zoom.',
    initialViewState: {
      latitude: 51.48731,
      longitude: -0.132523,
      zoom: 15,
      pitch: 60,
      bearing: 0,
      transitionDuration: 2000,
    },
    initialViewStateFinal: {
      longitude: -0.128,
      zoom: 13,
    },
    secondViewState: {
      latitude: 51.496603,
      longitude: -0.142479,
      zoom: 13,
      pitch: 0,
      bearing: 0,
      transitionDuration: 2000,
    },
    secondViewStateMobile: {
      longitude: -0.128,
      zoom: 11,
    },
    initialTimestamp: 1580390400,
    loopLength: 1000,
    animationSpeed: 150,
    trailLength: null,
  },
  {
    id: "paris",
    title: "Paris",
    description: "Eiffel Tower surroundings. Tourism Afflux.",
    initialViewState: {
      latitude: 48.855903,
      longitude: 2.293096,
      zoom: 16,
      pitch: 60,
      bearing: 0,
      transitionDuration: 2000,
    },
    initialViewStateFinal: {
      longitude: 2.294,
      zoom: 15,
    },
    secondViewState: {
      latitude: 48.857258,
      longitude: 2.287667,
      zoom: 15,
      pitch: 0,
      bearing: 0,
      transitionDuration: 2000,
    },
    secondViewStateMobile: {
      longitude: 2.294,
      zoom: 13,
    },
    initialTimestamp: 1580488474,
    loopLength: 1500,
    animationSpeed: 30,
    trailLength: 30,
  },
]

const Smartcities = () => {
  const size = useWindowSize()
  const prevSize = usePrevious(size.width)
  const [isMobile, setIsMobile] = useState(false)

  const [city, setCity] = useState("madrid")

  const data = useStaticQuery(graphql`
    {
      allTripsMadridJson {
        edges {
          node {
            waypoints {
              coordinates
              timestamp
            }
          }
        }
      }
      allTripsLondonJson {
        edges {
          node {
            waypoints {
              coordinates
              timestamp
            }
          }
        }
      }
      allTripsParisJson {
        edges {
          node {
            waypoints {
              coordinates
              timestamp
            }
          }
        }
      }
    }
  `)

  useEffect(() => {
    if (size.width <= SCREEN_MOBILE_WIDTH) {
      setupMobile()
    }
  }, [])

  useEffect(() => {
    // checks
    const screenToMobile =
      prevSize > SCREEN_MOBILE_WIDTH && size.width <= SCREEN_MOBILE_WIDTH
    const screenFromMobile =
      prevSize <= SCREEN_MOBILE_WIDTH && size.width > SCREEN_MOBILE_WIDTH

    if (screenToMobile) {
      setupMobile()
    } else if (screenFromMobile) {
      setupDesktop()
    }
  }, [size])

  const setupMobile = () => {
    setIsMobile(true)
  }

  const setupDesktop = () => {
    setIsMobile(false)
  }

  const handleChangeCity = newCity => {
    setCity(newCity)
  }

  const getData = () => {
    switch (city) {
      case "madrid":
        return data.allTripsMadridJson.edges
      case "paris":
        return data.allTripsParisJson.edges
      case "london":
        return data.allTripsLondonJson.edges
      default:
        return data.allTripsMadridJson.edges
    }
  }

  const getPosition = d => d.node.coordinates

  const cityOpts = SMARTCITIES.find(item => item.id === city)

  return (
    <Layout view={"map"}>
      <SEO title="Stories - Smartcities" />
      <SmartcityStory
        isMobile={isMobile}
        city={city}
        data={getData()}
        mapOptions={cityOpts}
        getPosition={getPosition}
        handleChangeCity={handleChangeCity}
        links={SMARTCITIES.map(item => ({
          id: item.id,
          title: item.title,
        }))}
      />
    </Layout>
  )
}

export default Smartcities
